import React from "react"
import { graphql } from "gatsby"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import Style from "./article.module.scss"

const Article = class extends React.Component {
  render() {
    const article = this.props.data.markdownRemark
    return (
      <PageLayout
        title="お知らせ"
        enTitle="NEWS"
      >
        <SEO title={article.frontmatter.title} />
        <section className={Style.article}>
          <h1 className={Style.title}>{article.frontmatter.title}</h1>
          <time className={Style.date}>{article.frontmatter.date}</time>
          <div className={Style.content} dangerouslySetInnerHTML={{ __html: article.html }} />
        </section>
      </PageLayout>
    );
  }
}

export const query = graphql`query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      title
      date(formatString: "yyyy.MM.DD")
    }
  }
}
`

export default Article
